<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				Details
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value="preferencePageTranslation.preferencePageName"
							label="Preference Page Name"
							disabled
						/>
					</v-col>
					<v-col cols="6">
						<TextField
							:value="preferencePageTranslation.brandName"
							label="Brand"
							disabled
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value="preferencePageTranslation.languageName"
							label="Language"
							disabled
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<SectionCard>
			<template #title>
				Authentication Page Translations
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<TextArea
							:value="preferencePageTranslation.defaultVerificationAreaText"
							label="Default Authentication Area Text"
							test-id="preference_page_default_verification_area_text"
							disabled
							rows="1"
							auto-grow
							dense
						/>
					</v-col>
					<v-col cols="6">
						<TextArea
							:value.sync="preferencePageTranslation.verificationAreaText"
							label="Authentication Area Text Translation"
							test-id="preference_page_verification_area_text"
							:disabled="!userFullPermissions"
							:rules="{ max: 2000 }"
							rows="1"
							auto-grow
							dense
							@input="updatePreferencePageTranslation('verificationAreaText', $event)"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="preferencePageTranslation.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL"
					dense
				>
					<v-col cols="6">
						<TextArea
							:value="preferencePageTranslation.defaultVerificationSentMessage"
							label="Default Success Message"
							test-id="preference_page_default_verification_sent_message"
							disabled
							rows="1"
							auto-grow
							dense
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<TextArea
							:value.sync="preferencePageTranslation.verificationSentMessage"
							label="Success Message Translation"
							test-id="preference_page_verification_sent_message"
							:disabled="!userFullPermissions"
							:rules="{ max: 2000 }"
							rows="1"
							auto-grow
							dense
							@input="updatePreferencePageTranslation('verificationSentMessage', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<TextArea
							:value="preferencePageTranslation.defaultVerificationFailedMessage"
							label="Default Failure Message"
							test-id="preference_page_default_verification_failed_message"
							disabled
							rows="1"
							auto-grow
							dense
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<TextArea
							:value.sync="preferencePageTranslation.verificationFailedMessage"
							label="Failure Message Translation"
							test-id="preference_page_verification_failed_message"
							:disabled="!userFullPermissions"
							:rules="{ max: 2000 }"
							rows="1"
							auto-grow
							dense
							@input="updatePreferencePageTranslation('verificationFailedMessage', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<TextArea
							:value="preferencePageTranslation.defaultStandDakAuthFieldFriendlyName"
							label="Default Value Label Field"
							test-id="preference_page_default_stand_dak_auth_field_friendly_name"
							disabled
							rows="1"
							auto-grow
							dense
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<TextArea
							:value.sync="preferencePageTranslation.standDakAuthFieldFriendlyName"
							label="Value Label Field Translation"
							test-id="preference_page_stand_dak_auth_field_friendly_name"
							:rules="{ max: 50 }"
							:disabled="!userFullPermissions"
							rows="1"
							auto-grow
							dense
							@input="updatePreferencePageTranslation('standDakAuthFieldFriendlyName', $event)"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="preferencePageTranslation.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL"
					dense
				>
					<v-col cols="6">
						<TextArea
							:value="preferencePageTranslation.defaultVerificationSentMessage"
							label="Default Authentication Email Sent 'Success' Message"
							test-id="preference_page_default_verification_sent_message"
							disabled
							rows="1"
							auto-grow
							dense
						/>
					</v-col>
					<v-col
						cols="6"
					>
						<TextArea
							:value.sync="preferencePageTranslation.verificationSentMessage"
							label="Authentication Email Sent 'Success' Message Translation"
							test-id="preference_page_verification_sent_message"
							:disabled="!userFullPermissions"
							:rules="{ max: 2000 }"
							rows="1"
							auto-grow
							dense
							@input="updatePreferencePageTranslation('verificationSentMessage', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
	</div>
</template>
<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import TextArea from '../../../../../../../shared/components/textarea.vue'
import { AUTHENTICATION_TYPES } from '../preference-page-authentication-types.js'

export default {
	components: {
		SectionCard,
		TextField,
		TextArea
	},
	props: {
		preferencePageTranslation: {
			type: Object,
			required: true
		},
		userFullPermissions: {
			type: Boolean,
			required: true
		}
	},
	data () {
		return {
			AUTHENTICATION_TYPES
		}
	},
	methods: {
		updatePreferencePageTranslation (property, value) {
			this.$emit('update:preferencePageTranslation', {
				...this.preferencePageTranslation,
				[property]: value
			})
		}
	}
}
</script>
