<template>
	<ValidationForm #default="{ handleSubmit }">
		<ConsentCollectionLayout
			:title="pageTitle"
			caption="Manage drag-and-drop preference page translation"
		>
			<template #header-after>
				<Stepper
					:steps="steps"
					:step="step"
					hidden-header
					@update:step="step = $event"
				/>
			</template>
			<template #content>
				<StepperItems
					v-if="showManageScreen"
					:steps="steps"
					:step="step"
				>
					<template #0>
						<AuthenticationTranslationsStep
							:user-full-permissions="userFullPermissions"
							:preference-page-translation.sync="preferencePageTranslation"
						/>
					</template>
					<template #1>
						<BuildStep
							:channels="translatedChannelsAndStatements"
							:headers="translatedHeader"
							:footers="translatedFooter"
							:user-full-permissions="userFullPermissions"
							:translation-mode="true"
							type="preferencePage"
						/>
					</template>
				</StepperItems>
			</template>
			<template #footer>
				<PageActionRow>
					<template #actions>
						<SecondaryActionButton
							test-id="manage_preference_page_translation_back_to_overview_button"
							@click="backToOverview"
						>
							Back To Overview
						</SecondaryActionButton>
						<v-spacer />
						<SecondaryActionButton
							v-if="step === 1 && steps.length > 1"
							test-id="manage_preference_page_translation_previous_button"
							@click="handleSubmit(previousStep)"
						>
							Previous
						</SecondaryActionButton>
						<PrimaryActionButton
							v-if="step === 1 && isEdit && userFullPermissions"
							class="ml-2"
							test-id="manage_preference_page_translation_save_button"
							@click="handleSubmit(saveTranslation)"
						>
							Save Changes
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="step === BUILDER_STEP && !isEdit && userFullPermissions"
							class="ml-2"
							test-id="manage_preference_page_translation_finish_button"
							@click="handleSubmit(saveTranslation)"
						>
							Finish
						</PrimaryActionButton>
						<PrimaryActionButton
							v-if="step === 0 && steps.length > 1"
							test-id="manage_preference_page_translation_next_button"
							class="ml-2"
							@click="handleSubmit(nextStep)"
						>
							Next
						</PrimaryActionButton>
					</template>
				</PageActionRow>
			</template>
		</ConsentCollectionLayout>
		<NonFormUnsavedChangesModal
			v-if="showUnsavedChangesModal"
			:next="nextFunction"
			@cancel="showUnsavedChangesModal = false"
			@proceed="proceedFromUnsavedChanges"
		/>
	</ValidationForm>
</template>

<script>
import { mapGetters } from 'vuex'
import ValidationForm from '../../../../../../../shared/components/validation-form.vue'
import ConsentCollectionLayout from '../../consent-collection-layout.vue'
import Stepper from '../../../../../../../shared/components/stepper-v2.vue'
import StepperItems from '../../../../../../../shared/components/stepper-v2-items.vue'
import AuthenticationTranslationsStep from './authentication-translations-step.vue'
import BuildStep from '../../build-step.vue'
import PageActionRow from '../../../../../../../shared/components/page-action-row.vue'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import NonFormUnsavedChangesModal from '../../../../../../../shared/components/non-form-unsaved-changes-modal.vue'
import { LINKS_AREA_FULL_PERMISSIONS,	LINKS_PAGE_FULL_PERMISSIONS } from '../../../../../../../shared/permissions/admin-portal-permissions.js'
import { CONSENT_COLLECTION_PREFERENCE_PAGES } from '../../../../../router/route-names.js'
import { AUTHENTICATION_TYPES } from '../preference-page-authentication-types.js'
import { getPreferencePageTranslation, putPreferencePageTranslation } from '../../../../../../../shared/utils/api/preference-pages.js'
import { getTranslationSchema, getSchema, getTheme, postSchema, putSchema } from '../../../../../../../shared/utils/api/form-builder.js'
import { getPreferenceChannels } from '../../../../../../../shared/utils/api/channels.js'
import { headersAndFootersTypeIdEnum } from '../../../../../../../shared/enums/headers-and-footers.js'
import { getFooterTranslations, getHeaderTranslations } from '../../../../../../../shared/utils/api/headers-and-footers.js'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { defaultVueformBuilderFormat } from '../../../../../../../shared/utils/vueform-builder-format.js'
import { brandOptions } from '../../../../../../../shared/state/brands.js'
import { getEnv } from '../../../../../../../shared/utils/runtime-settings.js'
export default {
	components: {
		ValidationForm,
		ConsentCollectionLayout,
		Stepper,
		StepperItems,
		AuthenticationTranslationsStep,
		BuildStep,
		PageActionRow,
		PrimaryActionButton,
		SecondaryActionButton,
		NonFormUnsavedChangesModal
	},
	beforeRouteLeave (to, from, next) {
		if (this.showUnsavedModal) {
			this.nextFunction = next
			this.showUnsavedChangesModal = true
			return
		}
		next()
	},
	props: {
		id: {
			type: Number,
			required: true
		},
		languageId: {
			type: Number,
			required: true
		}
	},
	setup () {
		const isFormBuilderScriptLoaded = Array.from(document.getElementsByTagName('script')).some(script => script.src === getEnv('VUE_APP_FORMBUILDER_URL'))
		if (!isFormBuilderScriptLoaded) {
			const formBuilderScript = document.createElement('script')
			formBuilderScript.src = getEnv('VUE_APP_FORMBUILDER_URL')
			document.head.appendChild(formBuilderScript)
		}
		return {
			showSnackbar
		}
	},
	data () {
		return {
			AUTHENTICATION_TYPES,
			BUILDER_STEP: 1,
			step: 0,
			steps: [
				{
					title: 'Authentication Details',
					slot: 0,
					stepNumber: 0,
					stepComplete: false
				},
				{
					title: 'Form Translations',
					slot: 1,
					stepNumber: 1,
					stepComplete: false
				}
			],
			preferencePageTranslation: {},
			formBuilderThemeGUID: null,
			channels: [],
			headers: [],
			footers: [],
			showManageScreen: false,
			formSaved: false,
			showUnsavedChangesModal: false,
			nextFunction: null
		}
	},
	computed: {
		...mapGetters('auth', ['productAreaPermission']),
		isEdit () {
			return this.preferencePageTranslation?.translationFormBuilderSchemaGUID
		},
		pageTitle () {
			return `Manage ${this.preferencePageTranslation?.preferencePageName} Translation - ${this.preferencePageTranslation?.languageName}`
		},
		userFullPermissions () {
			return (this.productAreaPermission(LINKS_AREA_FULL_PERMISSIONS) || this.productAreaPermission(LINKS_PAGE_FULL_PERMISSIONS)) && this.userHasBrand
		},
		userHasBrand () {
			const brands = brandOptions.value.map(({ value }) => value)
			return brands.includes(this.preferencePageTranslation.brandId)
		},
		showAuthStep () {
			return this.preferencePageTranslation.authTypeId !== AUTHENTICATION_TYPES.DIRECT
		},
		translatedHeader () {
			const flattenedHeaders = this.headers.flat()
			const formHeader = JSON.parse(localStorage.getItem('vueform-builder'))?.schema?.cassieHeader?.id
			const matchingHeader = flattenedHeaders.find(({ id, languageId }) => id === formHeader && languageId === this.languageId)
			if (matchingHeader) {
				matchingHeader.html = matchingHeader.translatedHeaderHtml ? matchingHeader.translatedHeaderHtml : matchingHeader.html
				return [matchingHeader]
			} else {
				return []
			}
		},
		translatedFooter () {
			const flattenedFooters = this.footers.flat()
			const formFooter = JSON.parse(localStorage.getItem('vueform-builder'))?.schema?.cassieFooter?.id
			const matchingFooter = flattenedFooters.find(({ id, languageId }) => id === formFooter && languageId === this.languageId)
			if (matchingFooter) {
				matchingFooter.html = matchingFooter.translatedFooterHtml ? matchingFooter.translatedFooterHtml : matchingFooter.html
				return [matchingFooter]
			} else {
				return []
			}
		},
		translatedChannelsAndStatements () {
			const formSchema = JSON.parse(localStorage.getItem('vueform-builder'))?.schema
			const preferenceElements = Object?.keys(formSchema)?.filter(key => key?.includes('checkboxPreference') || key?.includes('radioPreference') || key?.includes('togglePreference'))
			const channelsUsed = []
			preferenceElements.forEach(elementKey => {
				const element = formSchema[elementKey]
				const channel = this.channels.find(channel => channel.channelId === element.channel)
				if (channel) {
					const translatedChannel = channel.channelTranslations.find(({ languageID }) => languageID === this.languageId)
					channel.channelName = translatedChannel?.translatedChannelName ?? channel.channelName
					const statement = channel?.statements?.find(statement => statement.statementID === element.statement)
					const statementTranslation = statement?.liveStatements.find(({ languageId }) => languageId === this.languageId)
					statement.statementText = statementTranslation?.statementText ?? statement.statementText
					channel.statements = [statement]
					channelsUsed.push(channel)
				}
			})
			return channelsUsed
		},
		schemaChanged () {
			const formHistory = JSON.parse(localStorage.getItem('vueform-history'))
			return formHistory?.length > 1
		},
		showUnsavedModal () {
			return this.step === this.BUILDER_STEP && this.schemaChanged && !this.formSaved
		}
	},
	async created () {
		await this.getPreferencePageTranslation()
		await this.getBuilderSupportingData()
		await this.getTranslationSchemaAndTheme()
		if (this.preferencePageTranslation.authTypeId === AUTHENTICATION_TYPES.DIRECT) {
			this.$delete(this.steps, 0)
			this.step = 1
		}
		this.showManageScreen = true
	},
	methods: {
		async getPreferencePageTranslation () {
			const { preferencePageTranslation } = await getPreferencePageTranslation(this.id, this.languageId)
			this.preferencePageTranslation = preferencePageTranslation
		},
		async getTranslationSchemaAndTheme () {
			let schemaData = { }
			if (this.isEdit) {
				const { data } = await getSchema(this.preferencePageTranslation.translationFormBuilderSchemaGUID)
				schemaData = data
			} else {
				const { data } = await getTranslationSchema(this.preferencePageTranslation.defaultFormBuilderSchemaGUID, this.languageId)
				schemaData = data
			}
			const { data } = await getTheme(this.preferencePageTranslation.formBuilderThemeGUID)
			const builderConfig = { ...defaultVueformBuilderFormat }
			builderConfig.schema = JSON.parse(schemaData.json)
			builderConfig.theme = JSON.parse(data.json)
			builderConfig.builder.themeName = data.themeName
			localStorage.setItem('vueform-builder', JSON.stringify(builderConfig))
		},
		async getBuilderSupportingData () {
			const [
				{ headerTranslations },
				{ footerTranslations },
				{ data: { channels } }
			] = await Promise.all([
				getHeaderTranslations(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getFooterTranslations(headersAndFootersTypeIdEnum.PREFERENCE_PAGES_AND_WIDGETS),
				getPreferenceChannels()
			])
			this.headers = headerTranslations
			this.footers = footerTranslations
			this.channels = channels
		},
		backToOverview () {
			this.$router.push({ name: CONSENT_COLLECTION_PREFERENCE_PAGES })
			localStorage.removeItem('vueform-builder')
			localStorage.removeItem('vueform-history')
		},
		nextStep () {
			this.step++
		},
		previousStep () {
			this.step--
		},
		async saveTranslation () {
			await this.saveSchema()
			await this.putPreferencePageTranslation()
			showSnackbar('Preference Page Translation Saved')
			this.formSaved = true
			this.backToOverview()
		},
		async saveSchema () {
			const formSchema = JSON.parse(localStorage.getItem('vueform-builder')).schema
			const schema = {
				brandId: this.preferencePageTranslation.brandId,
				isTemplate: false,
				json: JSON.stringify(formSchema),
				languageId: this.languageId,
				defaultLanguageSchemaGUID: this.preferencePageTranslation.defaultFormBuilderSchemaGUID
			}
			if (this.isEdit) {
				delete schema.languageId
				delete schema.defaultLanguageSchemaGUID
				await putSchema(this.preferencePageTranslation.translationFormBuilderSchemaGUID, schema)
			} else {
				await postSchema(schema)
			}
		},
		async putPreferencePageTranslation () {
			const payload = {
				languageId: this.languageId,
				authTypeId: this.preferencePageTranslation.authTypeId,
				standDakAuthFieldFriendlyName: this.preferencePageTranslation.standDakAuthFieldFriendlyName,
				verificationSentMessage: this.preferencePageTranslation.verificationSentMessage,
				verificationAreaText: this.preferencePageTranslation.verificationAreaText,
				verificationFailedMessage: this.preferencePageTranslation.verificationFailedMessage
			}
			await putPreferencePageTranslation(this.id, payload)
		},
		proceedFromUnsavedChanges () {
			localStorage.removeItem('vueform-builder')
			localStorage.removeItem('vueform-history')
			localStorage.removeItem('vueform-themes')
			this.showUnsavedChangesModal = false
		}
	}
}
</script>
